var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "form-wrapper",
      attrs: {
        "auto-complete": "off",
        model: _vm.form,
        rules: _vm.rules,
        "label-width": "120px",
      },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "Name", prop: "name" } },
        [
          _c("el-input", {
            attrs: { name: "name", type: "text", "auto-complete": "off" },
            model: {
              value: _vm.form.name,
              callback: function ($$v) {
                _vm.$set(_vm.form, "name", $$v)
              },
              expression: "form.name",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Reference", prop: "ref" } },
        [
          _c("el-input", {
            attrs: {
              name: "ref",
              type: "text",
              "auto-complete": "off",
              placeholder: "XXX",
            },
            model: {
              value: _vm.form.ref,
              callback: function ($$v) {
                _vm.$set(_vm.form, "ref", $$v)
              },
              expression: "form.ref",
            },
          }),
        ],
        1
      ),
      _c("hr"),
      _c("el-form-item", { attrs: { label: "Users" } }, [
        _c(
          "div",
          { staticClass: "team" },
          [
            _c("user-selector", {
              attrs: { users: _vm.team_users, selected: _vm.selected_team },
              on: { change: _vm.teamChange, add_user: _vm.handleAddUser },
            }),
          ],
          1
        ),
      ]),
      _c("hr"),
      _c(
        "el-form-item",
        { attrs: { label: "Recurring", prop: "recurring" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.form.recurring,
              callback: function ($$v) {
                _vm.$set(_vm.form, "recurring", $$v)
              },
              expression: "form.recurring",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Start date", prop: "start_date" } },
        [
          _c("el-date-picker", {
            staticStyle: { width: "100%" },
            attrs: {
              type: "date",
              format: "dd/MM/yyyy",
              "picker-options": _vm.picker_options,
            },
            model: {
              value: _vm.form.start_date,
              callback: function ($$v) {
                _vm.$set(_vm.form, "start_date", $$v)
              },
              expression: "form.start_date",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "End date", prop: "end_date" } },
        [
          _c("el-date-picker", {
            staticStyle: { width: "100%" },
            attrs: {
              type: "date",
              format: "dd/MM/yyyy",
              "picker-options": _vm.picker_options_end,
            },
            model: {
              value: _vm.form.end_date,
              callback: function ($$v) {
                _vm.$set(_vm.form, "end_date", $$v)
              },
              expression: "form.end_date",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "form-number",
          attrs: {
            label: _vm.form.recurring ? "Time per block" : "Time estimate",
            prop: "hours_estimate",
          },
        },
        [
          _c(
            "el-input",
            {
              attrs: { placeholder: "hours", clearable: "" },
              model: {
                value: _vm.form.hours_estimate,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "hours_estimate", $$v)
                },
                expression: "form.hours_estimate",
              },
            },
            [
              _c("span", { attrs: { slot: "append" }, slot: "append" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("pluralize")("hour", _vm.form.hours_estimate)
                    ) +
                    " "
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm.form.recurring
        ? [
            _c(
              "el-form-item",
              {
                staticClass: "form-number recurring",
                attrs: {
                  label: "Period",
                  prop: "recurring_config.period_size",
                },
              },
              [
                _c(
                  "el-input",
                  {
                    attrs: {
                      placeholder: _vm._f("pluralize")(
                        _vm.form.recurring_config.period_unit
                      ),
                    },
                    model: {
                      value: _vm.form.recurring_config.period_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.recurring_config, "period_size", $$v)
                      },
                      expression: "form.recurring_config.period_size",
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { slot: "append" },
                        slot: "append",
                        model: {
                          value: _vm.form.recurring_config.period_unit,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form.recurring_config,
                              "period_unit",
                              $$v
                            )
                          },
                          expression: "form.recurring_config.period_unit",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: {
                            value: "day",
                            label: _vm._f("pluralize")(
                              "day",
                              _vm.form.recurring_config.period_size
                            ),
                          },
                        }),
                        _c("el-option", {
                          attrs: {
                            value: "month",
                            label: _vm._f("pluralize")(
                              "month",
                              _vm.form.recurring_config.period_size
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.is_month_period
                  ? _c("span", { staticClass: "field-footnote" }, [
                      _vm._v(" " + _vm._s(_vm.period_message) + " "),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "form-number",
                attrs: {
                  label: "Expires after",
                  prop: "recurring_config.expires_in",
                },
              },
              [
                _c(
                  "el-input",
                  {
                    attrs: { placeholder: "count", clearable: "" },
                    model: {
                      value: _vm.form.recurring_config.expires_in,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.recurring_config, "expires_in", $$v)
                      },
                      expression: "form.recurring_config.expires_in",
                    },
                  },
                  [
                    _c("span", { attrs: { slot: "append" }, slot: "append" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("pluralize")(
                              "period",
                              _vm.form.recurring_config.expires_in
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]
                ),
                !_vm.form.recurring_config.expires_in ||
                _vm.form.recurring_config.expires_in === "0"
                  ? _c("span", { staticClass: "field-footnote" }, [
                      _vm._v(" Time allocated each block will not expire. "),
                    ])
                  : _c("span", { staticClass: "field-footnote" }, [
                      _vm._v(" Time allocated each block will expire after "),
                      _c("b", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.form.recurring_config.expires_in *
                                _vm.form.recurring_config.period_size
                            ) +
                            " " +
                            _vm._s(
                              _vm._f("pluralize")(
                                _vm.form.recurring_config.period_unit,
                                _vm.form.recurring_config.expires_in *
                                  _vm.form.recurring_config.period_size
                              )
                            )
                        ),
                      ]),
                      _vm._v(". "),
                    ]),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c("hr"),
      _c(
        "el-form-item",
        {
          staticClass: "form-number",
          attrs: { label: "Round to", prop: "round" },
        },
        [
          _c(
            "el-input",
            {
              attrs: { placeholder: "minutes", clearable: "" },
              model: {
                value: _vm.form.round,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "round", $$v)
                },
                expression: "form.round",
              },
            },
            [
              _c("span", { attrs: { slot: "append" }, slot: "append" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("pluralize")("minute", _vm.form.round)) +
                    " "
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm.form.round
        ? _c(
            "el-form-item",
            {
              staticClass: "form-number",
              attrs: { label: "Round after", prop: "min" },
            },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    placeholder: "minutes",
                    clearable: "",
                    disabled: !_vm.form.round,
                  },
                  model: {
                    value: _vm.form.min,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "min", $$v)
                    },
                    expression: "form.min",
                  },
                },
                [
                  _c("span", { attrs: { slot: "append" }, slot: "append" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("pluralize")("minute", _vm.form.min)) +
                        " "
                    ),
                  ]),
                ]
              ),
              _vm.form.round && _vm.form.min
                ? _c("span", { staticClass: "field-footnote" }, [
                    _vm._v(
                      " Once a user, for a given day, has tracked more than "
                    ),
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.form.min) +
                          " " +
                          _vm._s(_vm._f("pluralize")("minute", _vm.form.min))
                      ),
                    ]),
                    _vm._v(
                      ", the reportable time will be rounded up to the nearest "
                    ),
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.form.round) +
                          " " +
                          _vm._s(_vm._f("pluralize")("minute", _vm.form.round))
                      ),
                    ]),
                    _vm._v(". "),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-button",
        { attrs: { disabled: _vm.creating }, on: { click: _vm.handleSubmit } },
        [_vm._v(" Create ")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }